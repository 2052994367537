import { Chip, Divider, FormControl, Grid, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import Timeline, { DateHeader, SidebarHeader, TimelineHeaders, TimelineMarkers, TodayMarker } from 'react-calendar-timeline';
import './LocationTimeline.css';
import moment from 'moment-timezone';
import { getLocationsByUnit } from 'services/location';
import { getEvents } from 'services/user-events';
import { getUnits } from 'services/unit';
import { useSelector } from 'react-redux';
import { getProfile } from 'services/users';
import Logo from './../../../assets/images/Jope.png';

const LocationTimeline = () => {
    const [unit, setUnit] = useState({});

    const [units, setUnits] = useState([]);
    const [locations, setLocations] = useState([]);
    const [events, setEvents] = useState([]);

    const authUser = useSelector((state) => state.auth.user);

    const timezone = 'America/Sao_Paulo';

    const visibleTimeStart = moment.tz(timezone).hour(6).minute(0);
    const visibleTimeEnd = moment.tz(timezone).hour(20).minute(0);

    const fetchUnits = async () => {
        getUnits().then((resp) => {
            setUnit({});
            setUnits(resp.data.data);
        });
    };

    const fetchLocations = async () => {
        if (unit?.id) {
            getLocationsByUnit(unit.id).then((resp) => {
                setLocations(
                    [...resp.data.data].map((location) => ({
                        id: location.id,
                        title: location.description,
                        floor: location.floor
                    }))
                );
            });
        }
    };

    const fetchEvents = async () => {
        getEvents(moment.tz(timezone).format('YYYY-MM-DD')).then((resp) => {
            setEvents(
                [...resp.data].map((event) => ({
                    id: event.id,
                    group: event.location_id,
                    title: !event.private ? event.title : '(Privado)',
                    start_time: moment.tz(event.start, timezone),
                    end_time: moment.tz(event.end, timezone),
                    className: event.checks.length > 0 ? 'item-checked' : 'item-unchecked'
                }))
            );
        });
    };

    useEffect(() => {
        const interval = setInterval(() => {
            fetchEvents();
        }, 20000);
        return () => clearInterval(interval);
    }, [events]);

    useEffect(() => {
        getProfile(authUser.id).then((response) => {
            const { data } = response;
            if (data.default_unit_id) {
                const unitParam = units.find((u) => u.id == data.default_unit_id);
                if (unitParam) setUnit(unitParam);
            }
        });
    }, [units]);

    useEffect(() => {
        fetchLocations();
        fetchEvents();
    }, [unit]);

    useEffect(() => {
        fetchUnits();
    }, []);

    return (
        <MainCard>
            <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                <img src={Logo} alt="Jope - ISB" height={140} />
            </Stack>
            <Divider sx={{ my: 2 }} />
            <Grid container>
                <Grid item xs={12}>
                    <FormControl required fullWidth style={{ flex: '1' }}>
                        <InputLabel id="unit-label">Unidade</InputLabel>
                        <Select
                            labelId="unit-label"
                            label="Unidade"
                            value={unit}
                            onChange={(e) => setUnit(e.target.value)}
                            renderValue={(value) => value.description}
                        >
                            {units?.map((unit) => (
                                <MenuItem key={unit.id} value={unit}>
                                    {unit.description}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Divider sx={{ my: 2 }} />
            {visibleTimeStart && visibleTimeEnd && (
                <Timeline
                    groups={locations}
                    items={events}
                    defaultTimeStart={visibleTimeStart}
                    defaultTimeEnd={visibleTimeEnd}
                    buffer={1}
                    canMove={false}
                    canResize={false}
                    canChangeGroup={false}
                    sidebarWidth={280}
                    lineHeight={40}
                    groupRenderer={({ group }) => {
                        return (
                            <Stack display={'flex'} direction={'row'} justifyContent={'space-between'} alignItems={'center'} sx={{ px: 1 }}>
                                <Typography variant="h5">{group.title}</Typography>
                                <Typography variant="legend">{group.floor}</Typography>
                            </Stack>
                        );
                    }}
                    scrollRef={() => {}}
                >
                    <TimelineHeaders>
                        <SidebarHeader>
                            {({ getRootProps }) => {
                                return (
                                    <Stack {...getRootProps()} justifyContent={'center'} alignItems={'center'}>
                                        <Typography variant="h5" sx={{ color: 'white' }}>
                                            JOPE ISB - Open Space
                                        </Typography>
                                    </Stack>
                                );
                            }}
                        </SidebarHeader>
                        <DateHeader unit="primaryHeader" labelFormat={'dddd, LL'} />
                        <DateHeader labelFormat={'HH:mm'} />
                    </TimelineHeaders>
                    <TimelineMarkers>
                        <TodayMarker interval={10000}>
                            {({ styles }) => {
                                const customStyles = {
                                    ...styles,
                                    backgroundColor: 'red',
                                    width: 1
                                };
                                return <div style={customStyles} />;
                            }}
                        </TodayMarker>
                    </TimelineMarkers>
                </Timeline>
            )}
            <Stack direction="row" spacing={1} justifyContent="center" alignItems="center" sx={{ mt: 2 }}>
                <Chip label="Reservado" style={{ backgroundColor: '#ffc71f', color: '#263238' }} />
                <Chip label="Ocupado" style={{ backgroundColor: '#f44336', color: 'white' }} />
            </Stack>
        </MainCard>
    );
};

export default LocationTimeline;
